import React from 'react';
import PropTypes from 'prop-types';

import InfoWindowContent from 'components/maps/MultiMarkerMap/InfoWindow/InfoWindowContent';
import InfoWindowLinkComponent from 'components/maps/MultiMarkerMap/InfoWindow/InfoWindowLinkComponent';

function InfoWindow({ 
  selectedPlace, 
  isFlightSearch, 
  marker_window_airfield_code, 
  marker_window_distance,
  language,
}) {

  return (
    <React.Fragment>
      <InfoWindowContent 
        isFlightSearch={isFlightSearch}
        selectedPlace={selectedPlace}
        marker_window_airfield_code={marker_window_airfield_code}
        marker_window_distance={marker_window_distance}
      />
      <InfoWindowLinkComponent 
        isFlightSearch={isFlightSearch}
        selectedPlace={selectedPlace}
        language={language}
      />
      <div id="fsButton" />
    </React.Fragment>

  )
}

InfoWindow.propTypes = {
  isFlightSearch: PropTypes.bool,
  language: PropTypes.string,
  marker_window_airfield_code: PropTypes.string,
  marker_window_distance: PropTypes.string,
  selectedPlace: PropTypes.object
}

export default InfoWindow;
