import React from 'react'
import PropTypes from 'prop-types'

import InternalLink from 'components/links/internalLink';

function InfoWindowLinkComponent({ isFlightSearch, selectedPlace, language }) {
  return (
    <React.Fragment>
      {
        isFlightSearch ?
          null
        : 
        (
          <InternalLink 
            path={selectedPlace.markerData ? selectedPlace.markerData.url : ''}
            language={language}
            linkComponent={
              <p style={{fontWeight: 400, fontSize: '14px', padding: '10px 0', color: 'black'}}>
                {selectedPlace.markerData ? selectedPlace.markerData.name : ''}
              </p>
              }
          />
        )
      }
    </React.Fragment>
  )
}

InfoWindowLinkComponent.propTypes = {
  isFlightSearch: PropTypes.bool,
  language: PropTypes.string,
  selectedPlace: PropTypes.object,
}

export default InfoWindowLinkComponent
