import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

const isSwissDomain = process.env.IS_SWISS_DOMAIN;

const markerCluster = (props) => {

   const { map, google, locations, countryIndexPageUrl, language } = props;

   let clusterer= null;

   const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true
   });

   const infoWindowLinkStyle = `
      color: black; 
      margin: 15px; 
      font-weight: 400; 
      text-decoration: underline;
   `;

   const isSwissFrench = isSwissDomain && (language === "fr-fr" || language === "fr-ch");

   const locationMarkers = locations.map((el, i) => {

      const airfieldURL = `${isSwissFrench ? '/fr' : ''}/${countryIndexPageUrl}/${el.airfield_name
         .toLowerCase()
         .replace(/ /gi, '-')}-airport-A${el.id}${process.env.GATSBY_URL_SUFFIX}`
         .replace('airport-airport', 'airport');
         
      const marker = new google.maps.Marker({
         position: { lat: el.latitude, lng: el.longitude },
         icon : {
            url: 'https://images.prismic.io/privatefly/1572f20d-1acd-407d-ba2c-1284f1d1d7c2_map_icon.png?auto=compress,format',
            scaledSize: new google.maps.Size(35, 35),
            size: { width: 35, height: 35 },
         }
      })
         
      marker.addListener("click", (event) => {
         infoWindow.setContent(`
            <a style="${infoWindowLinkStyle}" href="${airfieldURL}">
               ${el.airfield_name}
            </a>
         `);

         infoWindow.open(map, marker);
      });
               
      return marker;
   });
   
   clusterer = new MarkerClusterer({ 
      locationMarkers, 
      map, 
      algorithm: new SuperClusterAlgorithm({ 
         maxZoom: 8,
         radius: 70, 
         minPoints: 2, 
         extent: 356, 
         // nodeSize: 64, 
         // gridSize: 64 
       }),
   });

   if (google?.maps?.event && map) {
      const clearMarkers = (el) => clusterer.clearMarkers(el.filter(marker => !map.getBounds().contains(marker.getPosition())));
      const setMarkers = (el) => clusterer.addMarkers(el.filter(marker => marker.getVisible() && map.getBounds().contains(marker.getPosition())));

      // add event listener for map load
      google.maps.event.addListener(map, "idle", () => {
         setMarkers(locationMarkers);
      })
      
      // add event listener when map is dragged
      google.maps.event.addListener(map, "dragend", () => {
         clearMarkers(locationMarkers);
      });
      
      // add event listener for zoom change
      google.maps.event.addListener(map, "zoom_changed", () => {
         clearMarkers(locationMarkers);
      })

   }
}  

export default markerCluster