import React, { Component } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import axios from 'axios';
import { GoogleApiWrapper, Marker } from 'google-maps-react';
import MarkerCluster from 'components/maps/MultiMarkerMap/MarkerCluster';

import Button from 'components/buttons/button';

import InfoWindow from 'components/maps/MultiMarkerMap/InfoWindow';
import StaticMap from 'components/maps/MultiMarkerMap/StaticMap';

import calculateDistance from 'components/maps/MultiMarkerMap/functions/calculateDistance';
import createMarkerData from 'components/maps/MultiMarkerMap/functions/createMarkerData';

import { StyledMapContainer, StyledInfoWindow, StyledAutoCompleteMessage } from 'components/maps/styled';
import { StyledInput } from 'styled';

class MultiMarkerMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      airfields: [],
      position: null,
      activeMarker: {},
      showingInfoWindow: false,
      selectedPlace: {},
      zoom: 5,
      staticmap: true,
      autoCompleteError:false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.showDynamicMap);
 }

 componentDidUpdate(){
  window.removeEventListener('scroll', this.showDynamicMap);
 }

  componentWillUnmount() {
    let autocompleteContainer = document.querySelector('.pac-container');

    if (autocompleteContainer) {
      autocompleteContainer.remove()
    }
  }

  handleInputFocus() {
    //add a custom id to autocomplete dropdown to overwrite position: fixed styles from modal in a flight search
    const autocompleteContainer = document.querySelector('.pac-container')

    autocompleteContainer.setAttribute('id', 'gm-autocomplete-container')
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onMarkerClick = (props, marker, e) => {
    //remove any markers loaded to a map with autocomplete event
    if (this.state.activeMarker && this.state.activeMarker.setMap) {
      this.state.activeMarker.setMap(null);
    }

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false, 
        activeMarker: null
      });
    }
  };

  showDynamicMap=()=>{
    this.setState({staticmap: false})
  }

  //google maps function to render autocomplete and search for airfields in a 300km radius from the location of input query
  fetchPlaces = (mapProps, map) => {
    const { google } = mapProps;
    
    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);

    autocomplete.bindTo('bounds', map);
    
    autocomplete.addListener('place_changed', () => {
      //remove a previous marker created with autocomplete
      if (this.state.activeMarker && this.state.activeMarker.setMap) {
        this.state.activeMarker.setMap(null);
      }

      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // show autocomplete error message on form submit if item does not exist within the list
        this.setState({autoCompleteError:true});
        return
      };

      if (place.geometry.viewport) {
        let bounds = new google.maps.LatLngBounds()

        // remove autocomplete error message if item found in list
        if(place.address_components){
          this.setState({autoCompleteError:false});
        }

        map.fitBounds(bounds)
      } else {
        let bounds = new google.maps.LatLngBounds()

        map.setCenter(place.geometry.location);
        map.fitBounds(bounds)
      }

      //get all the airfields around the center of the chosen location - range 300km

      let arrayOfAllDistances = []

      this.setState({ position: place.geometry.location }, () => {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        //call lambda that calculates distance and gets closest airfields
        axios.get(`${process.env.GATSBY_GET_NEAREST_AIRFIELDS_URL}/?lat=${latitude}&lng=${longitude}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          crossDomain: true
        })
        .then(result => {
            //get airfields in the range of 300 kms to the chosen location
          const filteredResult = result.data.filter(el => {
            const countryRegex = /Cuba|Iran|Korea \(North\)|Syria/;
            const icaoCodeRegex = /UKFF|UKFK|UKFB/;

            if (!countryRegex.test(el.country_name) && !icaoCodeRegex.test(el.icao_code)) {

              return el
            }
          }).filter(el => el !== undefined);

          filteredResult.map(el => {
              let latLng = new google.maps.LatLng(el.latitude, el.longitude);
              let distance = google.maps.geometry.spherical.computeDistanceBetween(this.state.position, latLng);

              arrayOfAllDistances.push(distance);

              this.setState({ airfields: filteredResult })

              return el
          });

          //get the closest airfield from the result data
          const closestAirfield = Math.min(...arrayOfAllDistances).toFixed();

          result.data.map(el => {
            const countryRegex = /Cuba|Iran|Korea \(North\)|Syria/;
            const icaoCodeRegex = /UKFF|UKFK|UKFB/;
            
            if (!countryRegex.test(el.country_name) && !icaoCodeRegex.test(el.icao_code)) {
              let latLng = new google.maps.LatLng(el.latitude, el.longitude);
              let distance = google.maps.geometry.spherical.computeDistanceBetween(this.state.position, latLng);

              const airfieldString = `${el.airfield_name} (${el.country_name}) ${el.icao_code} / ${el.iata_code}`

              if (distance.toFixed() === closestAirfield) {
                let activeMarker;

                // Distance of airport for homepage flight search component
                let distanceKm = distance.toFixed()/1000;
                let distanceMiles = Math.round(distanceKm/1.6)

                activeMarker = new google.maps.Marker({ 
                  map: map,
                  position: latLng,
                  markerData: {
                    url: `/${this.props.countryIndexPageUrl}/${el.airfield_name.toLowerCase().replace(/ /gi, '-')}-airport-A${el.id}${process.env.GATSBY_URL_SUFFIX}`.replace('airport-airport', 'airport'), 
                    id: parseInt(el.id), 
                    name: el.airfield_name,
                    code: el.iata_code,
                    latitude: parseFloat(el.latitude),
                    longitude: parseFloat(el.longitude),
                    flightSearchDistance: distanceMiles,
                    airfieldString: airfieldString,
                    timeZone: el.time_zone
                  },
                });

                activeMarker.setVisible(true);

                this.setState({
                  activeMarker: activeMarker,
                  selectedPlace: activeMarker,
                  showingInfoWindow: true
                })
              }
            }
          });    
        })
      });
    });
  }

  fsSelectAirport(props) {
    const {airfieldString, timeZone, id } = this.state.selectedPlace.markerData;

    const button = (
      <Button 
        className="info-window-button"
        id="info-window-button"
        fontColor="white"
        marginBottom='0'
        padding='4px 10px'
        fontSize='13px'
        fontWeight='500'
        text={props.mapMarkerText}
        onClick={() => {
          props.newAirportSelect({id: id});
          props.onCloseModal();
        }}
      />  
    );

    if (document.getElementById('fsButton') !=null) {
      ReactDOM.render(
        React.Children.only(button),
        document.getElementById("fsButton")
      );
    }
  }

  render() {
    const { 
      google, 
      mapMarkerText, 
      mapInputPlaceHolder, 
      mapInputAutocompleteReminder,
      isCountryPage, 
      countryPageAirfields,
      countryIndexPageUrl,
      isFlightSearch,
      mapIcon,
      marker_window_airfield_code,
      marker_window_distance,
      language
    } = this.props;

    const centerLat = countryPageAirfields && countryPageAirfields[0].latitude;
    const centerLng = countryPageAirfields && countryPageAirfields[0].longitude;

    return (
      <React.Fragment>
        {!isCountryPage ? (
          <div style={{position: 'relative'}}>
            <form 
              id='pac-input-wrapper'
              onSubmit={this.onSubmit} 
              style={{position: 'relative'}}
            >
              <StyledInput
                style={ isFlightSearch && this.state.autoCompleteError ? 
                  { borderColor: 'red', borderWidth: '2px' } : {} 
                }
                id='pac-input'
                aria-label={mapInputPlaceHolder}
                // onFocus={!isFlightSearch && this.handleInputFocus}
                width='235px'
                height='46px'
                type='text' 
                placeholder={mapInputPlaceHolder}
                ref={ref => (this.autocomplete = ref)}
              />
            </form>
          </div>
          ) : null 
        }

        {!isFlightSearch && this.state.autoCompleteError ? (
          <div style={{display:'block'}}>
            <StyledAutoCompleteMessage>!</StyledAutoCompleteMessage>
            <span style={{color:'white'}}>{mapInputAutocompleteReminder}</span>
          </div>
          ) : null
        }

        {isCountryPage && countryPageAirfields?.length > 0 ? (
          <React.Fragment>
            { this.state.staticmap===true ? <StaticMap lat={centerLat} long={centerLng} /> : (

              <StyledMapContainer 
                google={google}
                style={{width: '100%', height: '100%', position: 'relative'}}
                initialCenter={{lat: centerLat, lng: centerLng}}
                zoom={6}
              >

                <MarkerCluster
                  google={google}
                  locations={countryPageAirfields}
                  countryIndexPageUrl={countryIndexPageUrl}
                  center={{lat: centerLat, lng: centerLng}}
                  language={language}
                />

              </StyledMapContainer>

            )} 
          </React.Fragment> 

        ) : (

          <StyledMapContainer
            {...this.props}
            google={google}
            initialCenter={{ lat: 51.5285582, lng: -0.2416798 }}
            onReady={this.fetchPlaces}
            onClick={this.onMapClicked}
            zoom={this.state.zoom}
            center={this.state.position} 
            id="map-container"
          >
            {this.state.airfields.map((el, index) => {
              const distanceMiles = calculateDistance(google, el, this.state.position);
              const { airfieldURL, airfieldString, latitude, longitude } = createMarkerData(countryIndexPageUrl, el);

              return (
                <Marker 
                  onClick={this.onMarkerClick}
                  position={{lat: latitude, lng: longitude}}
                  icon={mapIcon}
                  markerData={{
                    url: airfieldURL,
                    id: parseInt(el.id), 
                    name: el.airfield_name,
                    code: el.iata_code,
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude),
                    flightSearchDistance: distanceMiles,
                    airfieldString: airfieldString
                  }}
                />
              )

            })}
            
            <StyledInfoWindow
              visible={this.state.showingInfoWindow}
              marker={this.state.activeMarker ? this.state.activeMarker : null}
              onOpen={ isFlightSearch ? () => this.fsSelectAirport(this.props): null }
            >
              <InfoWindow 
                selectedPlace={this.state.selectedPlace}
                isFlightSearch={isFlightSearch}
                marker_window_airfield_code={marker_window_airfield_code}
                marker_window_distance={marker_window_distance}
                language={language}
                closeModal={this.props.onCloseModal}
              />
            </StyledInfoWindow> 
          </StyledMapContainer> 

        )}

      </React.Fragment>
    );
  }
};

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  libraries: ['places', 'geometry'],
})(MultiMarkerMap);

MultiMarkerMap.propTypes = {
  countryIndexPageUrl: PropTypes.string,
  countryPageAirfields: PropTypes.array,
  google: PropTypes.object,
  isCountryPage: PropTypes.bool, 
  isFlightSearch: PropTypes.bool,
  language: PropTypes.string,
  mapIcon: PropTypes.string,
  mapInputAutocompleteReminder: PropTypes.string,
  mapInputPlaceHolder: PropTypes.string,
  mapMarkerText: PropTypes.string,
  marker_window_airfield_code: PropTypes.string,
  marker_window_distance: PropTypes.string,
  onCloseModal: PropTypes.func,
  slice: PropTypes.object,
  zoom: PropTypes.number
};