import React from 'react'
import PropTypes from 'prop-types'

function InfoWindowContent({ 
  isFlightSearch, 
  selectedPlace, 
  marker_window_airfield_code, 
  marker_window_distance 
}) {
  return (
    <React.Fragment>
      {isFlightSearch ?
        (
          <div style={{ textAlign: 'left' }}> 
            <p className="infoP">
              {selectedPlace.markerData ? selectedPlace.markerData.name : ''}
            </p>
            {marker_window_airfield_code && 
              <p className="infoP">{marker_window_airfield_code}: {selectedPlace.markerData && selectedPlace.markerData.code}</p>
            }
            {marker_window_distance && 
              <p className="infoP">{marker_window_distance}: {selectedPlace.markerData && selectedPlace.markerData.flightSearchDistance} miles</p>
            }
          </div>
        ) : 
        (
          <p style={{fontWeight: 400, fontSize: '14px', padding: '10px 0', color: 'black'}}>
            {selectedPlace.markerData ? selectedPlace.markerData.name : ''}
          </p>
        )
      }
    </React.Fragment>
  )
}

InfoWindowContent.propTypes = {
  isFlightSearch: PropTypes.bool,
  marker_window_airfield_code: PropTypes.string,
  marker_window_distance: PropTypes.string,
  selectedPlace: PropTypes.object,
}

export default InfoWindowContent
