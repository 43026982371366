export default function createMarkerData(countryIndexPageUrl, airfield) {
  const airfieldURL = `/${countryIndexPageUrl}/${airfield.airfield_name
    .toLowerCase()
    .replace(/ /gi, '-')}-airport-A${airfield.id}${process.env.GATSBY_URL_SUFFIX}`
    .replace('airport-airport', 'airport');

  const airfieldString = `${airfield.airfield_name} (${airfield.country_name}) ${airfield.icao_code} / ${airfield.iata_code}`;
  const countryAirfieldName = airfield.airfield_name;
  const latitude = airfield.latitude;
  const longitude = airfield.longitude;

  return {
    airfieldURL,
    airfieldString,
    countryAirfieldName,
    latitude,
    longitude
  }
}